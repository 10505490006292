import Vue from "vue";
import { titleCase, getEnv } from "@/utils/util";
import { merge, find } from "lodash";

function getAppInfo(value) {
  const { company, application } = value;
  const appInfo = merge(application, company);
  appInfo.company = titleCase(appInfo.companyName);
  return appInfo;
}

function view(name) {
  return (resolve) => {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    require([`@/${name}`], resolve);
  };
}

function initializeStore(store, data) {
  store.commit(
    "appConfig/setApplicationConfig",
    merge(
      {},
      store.getters["appConfig/getApplicationConfigs"],
      data.application,
    ),
  );

  store.commit("appConfig/setModulesConfig", data.modules);
}

export const mergeRemoteRoutes = (remoteRoute) => {
  const path = `${remoteRoute.name}/routes`;
  let mergedRoutes = {};
  try {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const moduleRoutes = require(`@/modules/${path}`).default[0];
    mergedRoutes = merge({}, moduleRoutes, remoteRoute);
    if (remoteRoute.children) {
      // mergedRoute is an array merge for children. need to re-merge children since they could be out of order.
      const children = [];
      remoteRoute.children.forEach((child) => {
        const moduleRouteChild = find(moduleRoutes.children, {
          name: child.name,
        });
        if (!moduleRouteChild) return;
        const mergedChild = merge(moduleRouteChild, child);
        children.push(mergedChild);
      });
      mergedRoutes.children = children;
    }
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(error);
    return error;
  }

  return mergedRoutes;
};

export const mergeRemoteModule = (store, moduleName, moduleConfig = {}) => {
  const path = `${moduleName}/modules`;
  const storePath = `${moduleName}/store`;
  const apiPath = `${moduleName}/api`;

  try {
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const module = require(`@/modules/${path}`);
    // eslint-disable-next-line global-require,import/no-dynamic-require
    const moduleStore = require(`@/modules/${storePath}`);

    try {
      // eslint-disable-next-line global-require,import/no-dynamic-require
      const moduleApi = require(`@/modules/${apiPath}`);
      moduleApi.default.registerApi();
    } finally {
      store.registerModule(moduleName, moduleStore.default);
      // Set module config
      store.commit(`${moduleName}/setConfig`, {
        moduleConfig: moduleConfig ?? {},
        companyName: Vue.$appInfo.company.toLowerCase(),
      });
      store.commit("appConfig/setModuleConfig", {
        key: moduleName,
        payload: module.default,
      });
    }

    return module.default;
  } catch (error) {
    return {};
  }
};

function initializeRoutes(router, data, store) {
  const { routes } = data;

  routes.forEach((route) => {
    const currentRoute = mergeRemoteRoutes(route);

    store.commit("appConfig/setModuleRoute", currentRoute);

    const routeClone = JSON.parse(JSON.stringify(currentRoute));
    const { children } = routeClone;

    if (children) delete routeClone.children;

    if (routeClone.component) {
      routeClone.component = view(routeClone.component);
    }
    router.addRoute(routeClone);

    if (children) {
      children.forEach((child) => {
        if (child.children) {
          child.children.forEach((subChild) => {
            if (subChild.component) {
              subChild.component = view(subChild.component);
            }
            if (subChild.path) {
              router.addRoute(route.name, subChild);
            }
          });
          delete child.children;
        }

        if (child.component) {
          child.component = view(child.component);
        }
        if (child.path) {
          router.addRoute(route.name, child);
        }
      });
    }
    mergeRemoteModule(store, route.name, data?.config);
  });
}

async function fetchModule(moduleApiUrl) {
  try {
    const { data } = await Vue.$apis.applicationConfig.getModulesFromConfigURL(moduleApiUrl);

    const routes = data._embedded.routes.filter((route) => route.name !== "home");
    return {
      config: data._embedded.config,
      permissions: data._embedded.permissions,
      routes,
    };
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(`Unable to fetch data from URL: ${moduleApiUrl}`);
    throw error;
  }
}

async function initializeModuleRoutes(router, data, store) {
  /**
   * @type Array modules
   */
  const { modules } = data;

  // eslint-disable-next-line no-restricted-syntax
  for (let module of modules) {
    if ("api_url" in module) {
      if (module.api_url.substring(0, 1) === "$") {
        // is an env variable. resolve it
        module.api_url = getEnv(module.api_url.substring(1));
      }

      // eslint-disable-next-line no-await-in-loop
      module = await fetchModule(module.api_url);
    }
    initializeRoutes(router, module, store);
  }
}

export default async (store, router) => {
  let result = true;
  try {
    const { data } = await Vue.$apis.applicationConfig.getAppConfiguration();
    Vue.$appInfo = Object.freeze(getAppInfo(data._embedded));
    Vue.prototype.$appInfo = Object.freeze(Vue.$appInfo);

    initializeStore(store, data._embedded);
    await initializeModuleRoutes(router, data._embedded, store);
  } catch (e) {
    result = false;
  }

  return result;
};
