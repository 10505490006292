export default () => ({
  submitError: {},
  fetchError: null,
  isSubmitting: false,
  isFetchingData: false,
  progressMessage: "",
  successMessage: "",
  isFormValid: false,
  isLoading: false,
  error: null,

  claimStatusOptions: {
    EXEMPT: "E",
    SINGLE: "S",
    MARRIED: "M",
    HEAD_OF_HOUSEHOLD: "H",
  },
  personalInformation: {},
  currentW4Information: {},
  documentArchive: [],
  totalDocumentsInArchive: 0,
  formData: {
    claimingStatus: "", // S M H E
    additionalAmount: 0.0,
    exemptFlag: false,
    otherIncome: 0.0,
    deductions: 0.0,
    qualifyingChildrenAmount: 0.0,
    otherDependentAmount: 0.0,
    creditsSum: 0.0,
    otherCreditAmount: 0.0,
    newFlag: "0",
    multiJobsFlag: false,
    headHouseholdFlag: "0",
    employeeSignature: "",
    dateSigned: new Date(),
  },
  successMessages: {
    submit: {
      title: "W4 Form Update",
      message:
        "<p>Thank you!</p>"
        + "<p>Your W-4 Update has been submitted for processing.<br><br>"
        + "Please allow (1) one business day for update to apply.<br><br>"
        + "You will receive an email confirmation at your preferred email address: %s</p>",
    },
  },
  formSections: [
    {
      component: "personalInfo",
      title: "1. Personal Information",
      icon: "mdi-account",
      link: "#personalInfo",
      valid: true,
      enabled: true,
      hideFromExempt: false,
    },
    {
      component: "multipleJobs",
      title: "2. Multiple Jobs",
      icon: "mdi-briefcase-arrow-up-down",
      link: "#multipleJobs",
      valid: true,
      enabled: true,
      hideFromExempt: true,
    },
    {
      component: "claimDependents",
      title: "3. Claim Dependents",
      icon: "mdi-account-child",
      link: "#claimDependents",
      valid: true,
      enabled: true,
      hideFromExempt: true,
    },
    {
      component: "otherAdjustments",
      title: "4. Other Adjustments",
      icon: "mdi-cash",
      link: "#otherAdjustments",
      valid: true,
      enabled: true,
      hideFromExempt: true,
    },
    {
      component: "digitalSignature",
      title: "5. Digital Signature",
      icon: "mdi-pencil",
      link: "#digitalSignature",
      valid: true,
      enabled: true,
      hideFromExempt: false,
    },
  ],
  formTextStrings: {
    exemptMessage: "",
  },
  emailSentTo: "",
  w4PdfId: "",
  w4PdfTitle: "",
});
