<template>
  <v-card class="my-4">
    <v-card-text class="mt-2">
      <v-alert icon="mdi-information-variant" prominent color="blue" outlined class="w4InfoAlert">
        <p class="w4InfoAlertText" v-if="step === 2">
          <strong>TIP:</strong> Consider using the estimator at
          <a href="https://www.irs.gov/W4App" target="_blank" rel="noopener noreferrer">www.irs.gov/W4App</a>
          to determine the most accurate withholding for the rest of the year if: you are completing this form after the
          beginning of the year; expect to work only part of the year; or have changes during the year in your marital
          status, number of jobs for you (and/or your spouse if married filing jointly), dependents, other income (not
          from jobs), deductions, or credits. Have your most recent pay stub(s) from this year available when using the
          estimator. At the beginning of next year, use the estimator again to recheck your withholding.<br><br>
          <strong>Complete Steps 2-4 ONLY if they apply to you; otherwise, skip to Step 5.</strong> See
          <a @keydown.enter.stop="$emit('openPDF', 'page2')" @click.stop="$emit('openPDF', 'page2')">page 2</a>
          for more information on each step, who can claim exemption from withholding, and when to use the estimator at
          <a href="https://www.irs.gov/W4App" target="_blank" rel="noopener noreferrer">www.irs.gov/W4App</a>
        </p>
        <p class="w4InfoAlertText" v-if="step === 3">
          <strong>Complete Steps 3-4(b) on Form W-4 for only ONE of these jobs.</strong> Leave those steps blank for the
          other jobs. (Your withholding will be most accurate if you complete Steps 3-4(b) on the Form W-4 for the
          highest paying job.)
        </p>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "w4InfoCopy",
  props: {
    step: { type: Number, required: true },
  },
  data() {
    return {
      alignmentType: "center",
    };
  },
};
</script>

<style scoped>
.w4InfoAlert {
  border-color: transparent !important;
}
.w4InfoAlertText {
  color: rgba(0, 0, 0, 0.6);
  padding-top: 15px;
}
@media only screen and (max-width: 599px) {
  .w4InfoAlert::v-deep .v-alert__wrapper {
    display: block;
    text-align: center;
  }
  .w4InfoAlert::v-deep .v-alert__content {
    text-align: left;
  }
}
</style>
