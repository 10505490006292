<template>
  <div class="pto-entries-table-component" v-show="showTable">
    <v-card elevation="0">
      <v-card-subtitle class="grey lighten-2">
        Hours Requested
      </v-card-subtitle>
      <v-form ref="entriesTable">
        <v-simple-table class="request-entries-table">
          <thead>
            <tr>
              <th class="date-column text-left">Date</th>
              <th class="category-column text-left">Category</th>
              <th class="hour-column text-center">Hours</th>
              <th class="action-column text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="dateEntry in dateEntriesArray"
              :key="`${dateEntry.dateStr}-${dateEntry.entry.code}`"
              v-bind:class="hasError(dateEntry)"
            >
              <td class="text-left">
                {{ dateFormat(dateEntry.dateStr) }}
              </td>
              <td class="text-left">
                <span
                  v-bind:style="{
                    color: `var(--pto-cat-bg-color-${dateEntry.entry.code})`,
                  }"
                >
                  {{ categoryFormat(dateEntry.entry.code) }}
                </span>
              </td>
              <td class="text-center">
                <v-text-field
                  v-if="
                    employeeSchedulePayType === config('hourly_pay_type') ||
                    dateEntry.entry.isSplit ||
                    (isPayroll && otherEmployeeSelected)
                  "
                  dense
                  class="centered-input"
                  :value="dateEntry.entry.hours"
                  :rules="[
                    validateEntriesTable(
                      dateEntry.entry.hours,
                      dateEntry.entry.code,
                      dateEntry.dateStr,
                      dateEntry.entry.isSplit
                    ),
                  ]"
                  v-mask="['#.##', '##.##']"
                  @blur="onChangeHours(dateEntry, $event)"
                />
                <span v-else>
                  {{ dateEntry.entry.hours }}
                </span>
              </td>
              <td class="text-center">
                <v-icon
                  small
                  @click="
                    deleteDateEntry(dateEntry.dateStr, dateEntry.entry.code)
                  "
                >
                  mdi-delete
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-form>
      <v-alert
        type="error"
        outlined
        v-show="tableErrors"
        class="alert-box"
        v-html="tableErrors"
      ></v-alert>
    </v-card>
  </div>
</template>

<script>
import { Component, Mixins, Prop } from "vue-property-decorator";
import dates from "@/utils/dates";
import { namespace } from "vuex-class";
import { mask } from "vue-the-mask";
import rulesMixin from "@/modules/timeOff/rulesMixin";
import validatorMixin from "@/utils/validatorMixin";

const authStore = namespace("auth");
const requestFormStore = namespace("timeOff/requestForm");
const employeeHoursStore = namespace("timeOff/employeeHours");
const employeeScheduleStore = namespace("timeOff/employeeSchedule");

@Component({
  name: "RequestEntriesTable",
  directives: { mask },
})
export default class RequestEntriesTable extends Mixins(
  rulesMixin,
  validatorMixin
) {
  name = "requestEntriesTable.vue";

  @authStore.Getter("isPayroll") isPayroll;
  @employeeHoursStore.Getter("employeeHours") employeeHours;
  @employeeHoursStore.Getter("categoryFormat") categoryFormat;
  @employeeScheduleStore.Getter("employeeSchedule") employeeSchedule;
  @requestFormStore.Getter("requestEntries") requestEntries;
  @requestFormStore.Getter("dateEntriesArray") dateEntriesArray;
  @requestFormStore.Getter("getFormErrors") getFormErrors;
  @requestFormStore.Getter("otherEmployeeSelected") otherEmployeeSelected;
  @requestFormStore.Action("removeDateSelected") removeDateSelected;
  @requestFormStore.Action("updateRequestEntryHours") updateRequestEntryHours;

  @Prop({
    type: Boolean,
    default: false,
  })
  isModal;

  get showTable() {
    return Object.keys(this.requestEntries).length > 0;
  }

  hasError(dateEntry) {
    if (dateEntry.entry.error !== undefined) {
      return dateEntry.entry?.error !== "" ? "error" : "";
    }
    return "";
  }

  get tableErrors() {
    return this.getFormErrors;
  }

  dateFormat(value) {
    return dates.dateFormalString(value);
  }

  deleteDateEntry(dateStr, code) {
    this.removeDateSelected({ dateStr, code });
    this.$emit("entryRemoved", { dateStr: dateStr, code: code });
  }

  onChangeHours(entry, $event) {
    const change = {
      dateStr: entry.dateStr,
      code: entry.entry.code,
      hours: $event.target.value,
    };
    this.updateRequestEntryHours(change);
    // give a few cycles for changes to propagate and trigger a change event
    setTimeout(() => {
      this.$emit("hoursChanged", change);
    }, 50);
  }

  validateEntriesTable(hours, code, dateStr, isSplit) {
    let valid = this.requestEntryHoursRule(hours, code, dateStr, isSplit);

    if (valid !== true) {
      let options = {};
      if (this.isModal) {
        options.container = "#requestEditModal";
      }
      const timer = setTimeout(() => {
        this.goToErrorField(options);
        clearTimeout(timer);
      }, 100);
    }

    return valid;
  }
}
</script>

<style lang="scss" scoped>
.request-entries-table td:nth-child(1),
.request-entries-table td:nth-child(2) {
  width: 25%;
}
.request-entries-table td:nth-child(3) {
  width: 20%;
}
.request-entries-table td:nth-child(4) {
  width: 30%;
}
</style>
