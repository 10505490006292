<template>
  <fieldset>
    <legend>Filing Status</legend>
    Do you want to claim exempt?
    <v-switch v-model="exemptFlag" :label="`${isExempt}`" />
    <v-radio-group v-if="exemptFlag === false" v-model="claimingStatus" column :rules="[required]">
      <v-radio label="Single or Married filing separately" value="S" />
      <v-radio label="Married filing jointly or Qualifying surviving spouse" value="M" />
      <v-radio label="Head of household*" value="H" />
      <div class="sm1 text-sm-body-2">
        * Check only if you’re unmarried and pay more than half the costs of keeping up a home for yourself and a
        qualifying individual.
      </div>
    </v-radio-group>
    <div v-show="exemptFlag === true" class="claiming-disclaimer">
      <p>
        You may claim exemption from withholding for {{ currentYear }} if you meet both of the following conditions:
      </p>
      <ul>
        <li>
          You had no federal income tax liability in {{ priorYear }} and you expect to have no federal income tax
          liability in {{ currentYear }}
        </li>
        <li>
          You had no federal income tax liability in {{ priorYear }} if
          <ul>
            <li>
              (1) your total tax on line 24 on your {{ priorYear }} Form 1040 or 1040-SR is zero (or less than the sum
              of lines 27, 28, and 29), or
            </li>
            <li>
              (2) you were not required to file a return because your income was below the filing threshold for your
              correct filing status.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        If you claim exemption, you will have no income tax withheld from your paycheck and may owe taxes and penalties
        when you file your {{ currentYear }} tax return. To claim exemption from withholding, certify that you meet both
        of the conditions above.
      </p>
      <p>
        "Exempt" from withholding is valid for only the calendar year that it is furnished to the employer. Employees
        must provide a new W-4 to continue "Exempt" status by Feb. 17 of that year. If the employee does not provide the
        employer a valid W-4, withholding tax will be calculated as if he/she were single with no other adjustments.
      </p>
    </div>
  </fieldset>
</template>

<script>
import { Component, Inject, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import validators from "@/utils/validators";

const w4store = namespace("payroll/w4");

@Component({
  name: "ClaimingStatus",
})
export default class ClaimingStatus extends Vue {
  @w4store.State("formData") formData;

  @w4store.State("claimStatusOptions") claimStatusOptions;

  @w4store.Mutation("setFormData") setFormData;

  @Inject("currentYear") currentYear;

  get priorYear() {
    return (parseInt(this.currentYear, 10) - 1).toString();
  }

  get claimingStatus() {
    return this.formData.claimingStatus;
  }

  set claimingStatus(value) {
    this.setFormData({ claimingStatus: value, employeeSignature: "" });
    this.$emit("validate");
  }

  get exemptFlag() {
    return this.formData.exemptFlag;
  }

  set exemptFlag(value) {
    const claim = value ? this.claimStatusOptions.EXEMPT : "";
    this.setFormData({
      exemptFlag: value,
      employeeSignature: "",
      claimingStatus: claim,
    });
    this.$emit("validate");
  }

  get isExempt() {
    return this.formData.exemptFlag ? "Yes" : "No";
  }

  required(value) {
    if (this.formData.exemptFlag) {
      return true;
    }
    return validators.required(value) || "This field is required";
  }
}
</script>

<style scoped></style>
