<template>
  <div class="request-form-container clear-right-nav">
    <v-card elevation="2" :loading="isLoading ? 'grey lighten-2' : null">
      <v-card-title :class="cardHeaderClass">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="!isLoading">
        <errorAlert
          :error="noScheduleError"
          v-show="showNoScheduleError"
        >
          <template v-slot:button>
            <v-btn class="goToSchedule" @click="goToSchedule" outlined>
              Set Schedule
            </v-btn>
          </template>
        </errorAlert>
        <errorAlert
          :error="employeeScheduleFetchError"
          v-show="employeeScheduleFetchError !== null"
        >
        </errorAlert>
        <v-form ref="form" v-model="valid">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="6">
                <label class="text--primary font-weight-bold">
                  <span class="emph">1</span> Select Time Off Category
                </label>
                <dropdownBox
                  :items="employeeHours"
                  itemText="description"
                  :modelProperty="selectedCategory"
                  v-on:inputChange="onCategoryChange"
                  :rules="categoryRules"
                ></dropdownBox>
                <div class="pto-selected-hours" v-if="showHoursBalance">
                  <p class="text--primary font-weight-bold">
                    Current selected category hours summary
                  </p>
                  <hours-balance :employee-count="selectedCategory" />
                </div>
              </v-col>
              <v-col cols="12" md="6">
                <label class="text--primary font-weight-bold">
                  <span class="emph">2</span> Select Date(s)
                </label>
                <requestDatesPicker
                  v-if="!isLoading"
                  v-on:datesChanged="onDatesChange"
                  v-on:editEmployeeSchedule="openScheduleEdit"
                  :requestedDates="requestedDates"
                ></requestDatesPicker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <requestEntriesTable
                  v-on:hoursChanged="onHoursChange"
                  v-on:entryRemoved="onEntryRemoved"
                ></requestEntriesTable>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-alert
                  type="error"
                  outlined
                  v-show="entriesErrorMessage"
                  class="alert-box"
                  v-html="entriesErrorMessage"
                ></v-alert>
              </v-col>
              <v-col cols="12">
                <label class="text--primary font-weight-bold">
                  <span class="emph">3</span> Reason for request
                </label>
                <comments
                  :max-characters="500"
                  label=""
                  name="requestComment"
                  :propModel="reasonForRequest"
                  v-on:inputChange="onReasonChange"
                  :rules="[]"
                ></comments>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <employeeScheduleEditModal
      v-if="showEmployeeScheduleEditModal"
      @close="closeScheduleEdit"
      @scheduleSaved="scheduleEdited"
      :employeeUserId="employeeUserId"
      :otherEmployeeSelected="otherEmployeeSelected"
    />
  </div>
</template>

<script>
import rulesMixin from "@/modules/timeOff/rulesMixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { namespace } from "vuex-class";
import comments from "@/modules/timeOff/components/fields/comments";
import dropdownBox from "@/modules/timeOff/components/fields/dropdownBox";
import hoursBalance from "@/modules/timeOff/components/fields/hoursBalance";
import requestDatesPicker from "@/modules/timeOff/requests/components/requestVCalendarDatesPicker";
import requestEntriesTable from "@/modules/timeOff/requests/components/requestEntriesTable";
import employeeScheduleEditModal from "@/modules/timeOff/employeeSchedule/components/employeeScheduleEditModal.vue";
import { cloneDeep as _cloneDeep, filter as _filter, trim } from "lodash";
import dates from "@/utils/dates";
import stylesMixIn from "@/utils/stylesMixin";
import validatorMixin from "@/utils/validatorMixin";
import timeOffMixin from "@/modules/timeOff/timeOffMixin";
import ErrorAlert from "@/components/errorAlert.vue";

const authStore = namespace("auth");
const requestFormStore = namespace("timeOff/requestForm");
const employeeHoursStore = namespace("timeOff/employeeHours");
const employeeScheduleStore = namespace("timeOff/employeeSchedule");
const timeOffStore = namespace("timeOff");
const requestsEmployeeStore = namespace("timeOff/requestsEmployee");

@Component({
  name: "RequestForm",
  components: {
    ErrorAlert,
    hoursBalance,
    dropdownBox,
    comments,
    requestDatesPicker,
    requestEntriesTable,
    employeeScheduleEditModal,
  },
})
export default class RequestForm extends Mixins(
  rulesMixin,
  stylesMixIn,
  validatorMixin,
  timeOffMixin
) {
  valid = true;
  validEntries = false;
  isLoading = false;
  messageText = "";
  titleText = "New Time Off Request for ";
  showEmployeeScheduleEditModal = false;
  entriesErrorMessage = null;
  requestedDates = [];

  @authStore.Getter("isPayroll") isPayroll;

  @requestFormStore.Getter("requestEntries") requestEntries;
  @requestFormStore.Getter("requestSubmitError") requestSubmitError;
  @requestFormStore.Getter("selectedCategory") selectedCategory;
  @requestFormStore.Getter("reasonForRequest") reasonForRequest;
  @requestFormStore.Getter("transformedEntries") transformedEntries;
  @requestFormStore.Getter("isFormValid") isFormValid;
  @requestFormStore.Getter("getCurrentRequest") getCurrentRequest;
  @requestFormStore.Getter("otherEmployeeSelected") otherEmployeeSelected;
  @requestFormStore.Mutation("setSelectedCategory") setSelectedCategory;
  @requestFormStore.Mutation("setReasonForRequest") setReasonForRequest;
  @requestFormStore.Action("postRequest") postRequest;
  @requestFormStore.Mutation("resetRequest") resetRequest;
  @requestFormStore.Mutation("setRequestEntries") setRequestEntries;
  @requestFormStore.Mutation("setIsFormValid") setIsFormValid;
  @requestFormStore.Mutation("resetFormErrors") resetFormErrors;

  @employeeHoursStore.Getter("employeeHours") employeeHours;
  @employeeHoursStore.Getter("employeeHoursUserId") employeeHoursUserId;
  @employeeHoursStore.Action("fetchEmployeeHours") fetchEmployeeHours;
  @employeeHoursStore.Action("fetchEmployeeHoursExcludingRequest") fetchEmployeeHoursExcludingRequest;
  @employeeScheduleStore.Getter("employeeSchedule") employeeSchedule;
  @employeeScheduleStore.Getter("employeeScheduleUserId")
  employeeScheduleUserId;
  @employeeScheduleStore.Action("fetchEmployeeSchedule") fetchEmployeeSchedule;
  @employeeScheduleStore.Action("fetchEmployeeScheduleChanges")
  fetchEmployeeScheduleChanges;
  @employeeScheduleStore.Getter("employeeScheduleFirstName")
  employeeScheduleFirstName;
  @employeeScheduleStore.Getter("employeeScheduleLastName")
  employeeScheduleLastName;
  @employeeScheduleStore.Getter("employeeScheduleEmployerId")
  employeeScheduleEmployerId;
  @employeeScheduleStore.Getter("employeeScheduleEmployeeId")
  employeeScheduleEmployeeId;
  @employeeScheduleStore.Getter("employeeScheduleChanges")
  employeeScheduleChanges;
  @employeeScheduleStore.Getter("scheduleForDate")
  scheduleForDate;
  @employeeScheduleStore.Getter("employeeScheduleFetchError")
  employeeScheduleFetchError;
  @employeeScheduleStore.Getter("employeeScheduledDays") employeeScheduledDays;

  @timeOffStore.Getter("config") config;

  @requestsEmployeeStore.Getter("getTimeOffRequests") timeOffRequests;
  @requestsEmployeeStore.Getter("getEmployeeIdentifier") getEmployeeIdentifier;
  @requestsEmployeeStore.Mutation("setEmployeeIdentifier")
  setEmployeeIdentifier;
  @requestsEmployeeStore.Action("fetchEmployeeListTimeOffRequests")
  fetchEmployeeListTimeOffRequests;

  @Prop({
    type: String,
    required: true,
  })
  employeeUserId;

  @Prop({
    type: Boolean,
    default: false,
  })
  isModal;

  data() {
    return {
      categoryRules: this.isModal ? [] : [(v) => !!v || "Item is required"],
    };
  }

  get submitError() {
    return this.requestSubmitError;
  }

  get isSubmitError() {
    return Object.keys(this.requestSubmitError).length !== 0;
  }

  get showHoursBalance() {
    return this.selectedCategory !== null;
  }
  get title() {
    if (this.isLoading) {
      return "Loading.....";
    }
    if (this.isSaving) {
      return "Submitting Request";
    }

    return (
      this.titleText +
      this.employeeScheduleFirstName +
      " " +
      this.employeeScheduleLastName
    );
  }
  set message(value) {
    this.messageText = value;
  }
  get message() {
    return this.messageText;
  }

  get noScheduleError() {
    return {
      title: `No schedule found for ${this.employeeScheduleFirstName} ${this.employeeScheduleLastName}`,
      detail: "Please set a schedule before submitting a request",
    };
  }

  get showNoScheduleError() {
    return this.employeeScheduleChanges.length === 0
      && this.employeeScheduledDays === 0
      && this.employeeScheduleFetchError === null;
  }

  goToSchedule() {
    this.openScheduleEdit();
  }

  onCategoryChange(event) {
    this.validate();
    this.setSelectedCategory(event);
  }

  onReasonChange(event) {
    this.setReasonForRequest(event);
    this.validate();
  }

  onHoursChange() {
    this.validate();
  }

  onDatesChange() {
    this.entriesError(null);
    this.validate();
  }

  onEntryRemoved() {
    this.validate();
  }

  entriesError(error) {
    this.entriesErrorMessage = error;
  }

  validateSplitEntries(dateString, dateEntries, prevEntry) {
    let valid = true;

    if (dateEntries.length === 1 && prevEntry === null) {
      // skip
      return valid;
    }

    // clear any errors
    dateEntries.forEach((dateEntry) => (dateEntry.error = ""));

    const splitValid = this.requestSplitRule(
      dateString,
      dateEntries,
      prevEntry,
      this.requestId
    );

    if (splitValid !== true) {
      valid = false;
      dateEntries.forEach(
        (dateEntry) =>
          (dateEntry.error = `Invalid split for ${dates.dateFormalString(
            dateString
          )}: ${splitValid}`)
      );
    }

    return valid;
  }

  validateEntries() {
    let valid = true;
    let splitEntriesValid = true;
    let categoriesValid = true;
    this.validEntries = true;
    this.resetFormErrors();

    const requestEntries = _cloneDeep(this.requestEntries);

    // Ensure entries object is not empty
    valid = this.entriesExist(requestEntries);

    // Allow payroll to remove all the entries
    if (!valid
      && this.isPayroll
      && this.otherEmployeeSelected
    ) {
      return;
    }

    // Loop date keys
    Object.keys(requestEntries).forEach((dateStr) => {
      const requestEntriesPrevRequest = _filter(
        this.requestedDates,
        (entry) => dateStr in entry && entry[dateStr].requestId !== undefined
      );

      const prevEntry =
        requestEntriesPrevRequest.length > 0
          ? requestEntriesPrevRequest[0][dateStr]
          : null;

      // Validate Split Entries
      splitEntriesValid = this.validateSplitEntries(
        dateStr,
        requestEntries[dateStr],
        prevEntry
      );

      categoriesValid = this.validateCategoryGroups(requestEntries[dateStr]);

      const isSplitEntry =
        requestEntries[dateStr].length > 1 || prevEntry !== null;

      if (categoriesValid !== true) {
        this.validEntries = false;
        requestEntries[dateStr] = categoriesValid;
      }
      // Loop dateEntries
      requestEntries[dateStr].forEach((dateEntry) => {
        const dateValid = this.requestDateRule(
          dateStr,
          dateEntry.code,
          this.requestedDates,
          this.requestId
        );
        if (dateValid !== true) {
          valid = false;
          dateEntry.error = "";
          dateEntry.error = dateValid;

          return;
        }

        const codeValid = this.requestCodeRule(dateEntry.code);
        if (codeValid !== true) {
          valid = false;
          dateEntry.error = codeValid;

          return;
        }

        let hoursValid = true;

        if (!isSplitEntry) {
          hoursValid = this.requestEntryHoursRule(
            dateEntry.hours,
            dateEntry.code,
            dateStr
          );
        }

        if (hoursValid !== true) {
          valid = false;
          dateEntry.error = hoursValid;
        }
      });
    });

    this.setRequestEntries(requestEntries);

    this.validEntries =
      this.validEntries && valid && splitEntriesValid === true;

    this.setIsFormValid(this.validEntries);
  }

  async validate() {
    // this method is called by parent component for field validation
    this.valid = this.$refs.form.validate();

    this.validateEntries();

    this.valid = this.valid && this.validEntries;
    this.setIsFormValid(this.valid);

    if (!this.valid) {
      let options = {};
      if (this.isModal) {
        options.container = "#requestEditModal";
      }
      await this.$nextTick();
      this.goToErrorField(options);
    }
  }

  async submitForm() {
    const requestData = {
      reason: this.reasonForRequest,
      entries: this.transformedEntries,
    };

    if (this.valid === true) {
      await this.postRequest({
        employeeIdentifier: this.employeeUserId,
        requestData: requestData,
      });
    }
  }

  openScheduleEdit() {
    this.showEmployeeScheduleEditModal = true;
  }

  closeScheduleEdit() {
    this.showEmployeeScheduleEditModal = false;
  }

  async loadRequestedDates() {
    let params = [];
    params.limit = -1;
    params.sort = "createTimestamp:asc";

    if (!(this.isPayroll && this.otherEmployeeSelected)) {
      params.startDate = dates.dateString(this.getMinSelectableDate);
      params.endDate = dates.dateString(this.getMaxSelectableDate);
    }

    await this.fetchEmployeeListTimeOffRequests({
      employeeIdentifier: this.getEmployeeIdentifier,
      params: params,
    });

    let filteredRequests = this.filterDeletedEntries(this.timeOffRequests);
    filteredRequests.forEach((request) => {
      request.entries.forEach((entry) => {
        this.requestedDates.push({
          [dates.dateString(dates.dateFromMezzioDateJson(entry.date))]: {
            requestId: trim(request.id),
            code: entry.code,
            error: "",
            hours: entry.hours,
            isSplit: this.isSplit(entry, request),
            perc: this.calcPerc(entry),
            isPrev: this.isPreviousRequest(request),
          },
        });
      });
    });

    this.requestedDates = [
      ...new Map(
        this.requestedDates.map((item) => [JSON.stringify(item), item])
      ).values(),
    ];
  }

  calcPerc(entry) {
    const scheduledHours = this.scheduleForDate(entry.date.date);

    return parseFloat(entry.hours) / parseFloat(scheduledHours);
  }

  isSplit(entry, request) {
    const allEntries = request.entries;

    if (allEntries.length === 0) {
      return false;
    }

    let filteredEntries = allEntries.filter(
      (e) => e.date.date === entry.date.date
    );

    return filteredEntries.length === this.config("request_day_max_splits");
  }

  isPreviousRequest(request) {
    return request.id !== this.getCurrentRequest.id;
  }

  mounted() {
    this.setEmployeeIdentifier(this.employeeUserId);
  }

  scheduleEdited() {
    this.closeScheduleEdit();
    this.loadFormData(true);
  }

  loadFormData(refresh) {
    this.resetRequest();

    this.isLoading = true;

    this.fetchData(refresh).then(() => {
      this.$emit("onLoadingComplete", true);
      this.isLoading = false;
    });
  }

  async fetchData(refresh) {
    if (refresh || this.employeeHoursUserId !== this.employeeUserId) {
      await this.fetchEmployeeHoursExcludingRequest({
        employeeIdentifier: this.employeeUserId,
        excludeRequestId: this.requestId
      });
    } else {
        await this.fetchEmployeeHours(this.employeeUserId);
    }

    if (refresh || this.employeeScheduleUserId !== this.employeeUserId) {
      await this.fetchEmployeeSchedule(this.employeeUserId);
    }

    if (this.employeeScheduleFetchError !== null) return;

    await this.fetchEmployeeScheduleChanges({
      params: {
        limit: -1,
        page: 1,
        sort: "modifiedTimestamp:desc",
        employerId: this.employeeScheduleEmployerId,
        employeeId: this.employeeScheduleEmployeeId,
      },
    });

    await this.loadRequestedDates();
  }

  async created() {
    this.loadFormData(false);
  }
}
</script>

<style scoped>
.goToSchedule {
  margin-left: 1%;
}
</style>
