<template>
  <div>
    <v-form>
      <personal-info id="personalInfo" ref="personalInfo" @validate="validateW4" />
      <AccountLockedAlert v-show="isFilingStatusLocked" />
      <CheckEmailPreference :show="showEmailSetup" @closeDialog="showEmailDialog = false" :editUrl="emailEditUrl" />
      <w4-info-copy v-show="showComponent" :step="2" @openPDF="openPDF" />
      <MultipleJobs
        id="multipleJobs"
        ref="multipleJobs"
        v-show="showComponent"
        @validate="validateW4"
        @openPDF="openPDF"
      />
      <w4-info-copy v-show="showComponent" :step="3" />
      <claim-dependents id="claimDependents" ref="claimDependents" v-show="showComponent" @validate="validateW4" />
      <OtherAdjustments
        id="otherAdjustments"
        ref="otherAdjustments"
        v-show="showComponent"
        @validate="validateW4"
        @openPDF="openPDF"
      />
      <digital-signature
        id="digitalSignature"
        ref="digitalSignature"
        @validate="validateW4"
        v-show="showDigitalSignature"
      />
    </v-form>
    <pdf-view :dialog="showPDF" title="W4 PDF" :pdfURL="pdfSource" @closeDialog="closePDF" />
  </div>
</template>

<script>
import PersonalInfo from "@/modules/payroll/updateW4/components/personalInfo.vue";
import MultipleJobs from "@/modules/payroll/updateW4/components/multipleJobs.vue";
import AccountLockedAlert from "@/modules/payroll/updateW4/components/accountLockedAlert.vue";
import ClaimDependents from "@/modules/payroll/updateW4/components/claimDependents.vue";
import DigitalSignature from "@/modules/payroll/updateW4/components/digitalSignature.vue";
import { Component, Inject, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import OtherAdjustments from "@/modules/payroll/updateW4/components/otherAdjustments.vue";
import PdfView from "@/modules/payroll/updateW4/components/pdfView.vue";
import CheckEmailPreference from "@/modules/payroll/updateW4/components/checkEmailPreference.vue";
import W4InfoCopy from "@/modules/payroll/updateW4/components/w4InfoCopy.vue";
import modulePageMixin from "@/utils/mixins";
import { getEnv } from "@/utils/util";

const w4store = namespace("payroll/w4");
const payroll = namespace("payroll");
const authStore = namespace("auth");
const myInformationStore = namespace("personalInfo/myInformation");

@Component({
  name: "w4Form",
  components: {
    W4InfoCopy,
    CheckEmailPreference,
    OtherAdjustments,
    DigitalSignature,
    PersonalInfo,
    MultipleJobs,
    AccountLockedAlert,
    ClaimDependents,
    PdfView,
  },
})
export default class W4Form extends Mixins(modulePageMixin) {
  @w4store.Mutation("setIsFormValid") setIsFormValid;

  @w4store.Mutation("clearFormData") clearFormData;

  @w4store.State("formData") formData;

  @w4store.Getter("isFilingStatusLocked") isFilingStatusLocked;

  @myInformationStore.State("personalInfo") personalInfo;

  @authStore.Getter("isDriver") isDriver;

  @authStore.Getter("personalEmailAddress") personalEmailAddress;

  @authStore.Getter("employeeId") employeeId;

  @authStore.Action("reloadUserInfo") reloadUserInfo;

  @payroll.Getter("config") config;

  @Inject("currentYear") currentYear;

  showPDF = false;

  pdfSource = "";

  showEmailDialog = false;

  get pdfPaths() {
    return {
      page2: `/assets/${this.currentYear}/fw4-p2.pdf`,
      page3: `/assets/${this.currentYear}/fw4-p3-4.pdf`,
    };
  }

  openPDF(page) {
    this.pdfSource = this.pdfPaths[page];
    this.showPDF = true;
  }

  closePDF() {
    this.showPDF = false;
    this.pdfSource = "";
  }

  validateW4() {
    const personalInfoValid = this.$refs.personalInfo.validate();
    const digitalSignatureValid = this.$refs.digitalSignature.validate();
    const claimDependents = this.$refs.claimDependents.validate();
    const otherAdjustments = this.$refs.otherAdjustments.validate();

    this.setIsFormValid(
      !(
        !claimDependents
        || !otherAdjustments
        || !digitalSignatureValid
        || !personalInfoValid
      ),
    );
  }

  get showDigitalSignature() {
    return !this.isFilingStatusLocked && !this.emailNotSet;
  }

  get showEmailSetup() {
    return (
      !this.isFilingStatusLocked && this.emailNotSet && this.showEmailDialog
    );
  }

  get isExempt() {
    return this.formData.exemptFlag;
  }

  get showComponent() {
    if (this.isFilingStatusLocked) return false;
    return !this.isExempt;
  }

  get emailNotSet() {
    if (this.isDriver) {
      return this.isKnight
        ? this.personalInfo?.personalEmailAddress.trim() === ""
        : this.personalEmailAddress.trim() === "";
    }

    return false;
  }

  get emailEditUrl() {
    return this.isSwift
      ? `${getEnv(
        "VUE_APP_SWIFT_MSS_ESS_BASE_URL",
      )}/hr101/hr101pre.php?zcid=${this.employeeId.trim()}`
      : "";
  }

  async mounted() {
    if (this.isSwift) {
      await this.reloadUserInfo();
    }
    const timer = setTimeout(() => {
      this.showEmailDialog = true;
      clearTimeout(timer);
    }, 100);
    this.clearFormData();
  }
}
</script>
