<template>
  <div class="updateW4">
    <page-manager :isLoading="isLoading" :isEmpty="false" :error="error">
      <template #loading>
        <div class="loader">
          <v-progress-circular indeterminate color="accent" />
        </div>
      </template>
      <h2>{{ moduleTitle }}</h2>
      <navBar @submit="onSubmit" v-if="!isFilingStatusLocked" />
      <w4Form />
      <submitDialog :propIsSubmitting="isSubmitting" :propSubmitError="submitError" @clearErrors="clearErrors" />
      <successDialog
        :title="successDialog.title"
        :message="''"
        :messageHTML="successDialog.message"
        :show="successDialog.show"
        @close="closeSuccessDialog"
        :show-second-action="true"
        :second-action-label="'Download W4 PDF'"
        @secondActionClick="onOpenDocument"
      />
    </page-manager>
  </div>
</template>

<script>
/* eslint-disable class-methods-use-this */
import { stringFormat } from "@/utils/util";
import modulePageMixin from "@/utils/mixins";
import { namespace } from "vuex-class";
import { Component, Mixins } from "vue-property-decorator";
import successDialog from "@/components/successDialog.vue";
import submitDialog from "@/components/submitDialog.vue";
import PageManager from "@/components/pageManager.vue";
import _ from "lodash";
import W4Form from "./components/w4Form.vue";
import navBar from "./components/w4NavBar.vue";

const w4store = namespace("payroll/w4");
const emailPreferencesStore = namespace("payroll/emailPreferences");
const myInformationStore = namespace("personalInfo/myInformation");

@Component({
  name: "UpdateW4",
  components: {
    W4Form,
    PageManager,
    successDialog,
    submitDialog,
    navBar,
  },
  provide() {
    return { currentYear: this.currentYear };
  },
})
export default class UpdateW4 extends Mixins(modulePageMixin) {
  @w4store.State("isLoading") isLoading;

  @w4store.State("isSubmitting") isSubmitting;

  @w4store.State("formSections") formSections;

  @w4store.State("error") error;

  @w4store.State("w4PdfId") w4PdfId;

  @w4store.State("w4PdfTitle") w4PdfTitle;

  @w4store.State("successMessages") successMessages;

  @w4store.Mutation("setSubmitError") setSubmitError;

  @w4store.Mutation("setFormData") setFormData;

  @w4store.Mutation("clearFormData") clearFormData;

  @w4store.Getter("submitError") submitError;

  @w4store.Getter("isFilingStatusLocked") isFilingStatusLocked;

  @w4store.Getter("getEmailSentTo") getEmailSentTo;

  @w4store.Action("submitW4Form") submitW4Form;

  @w4store.Action("fetchW4Form") fetchW4Form;

  @w4store.Action("openW4Pdf") openW4Pdf;

  @emailPreferencesStore.Action("fetchEmailPreferences") fetchEmailPreferences;

  @myInformationStore.Action("fetchPersonalInformation") fetchPersonalInformation;

  successDialog = {
    message: "",
    show: false,
  };

  get moduleTitle() {
    return `W4 - Employee’s Withholding Certificate ${this.currentYear}`;
  }

  get currentYear() {
    // TODO: This should not be hardcoded, but we need to revisit how we update this.
    return "2025";
  }

  async onSubmit() {
    await this.submitW4Form(this.formData);
    this.showSuccessDialog("submit");
  }

  clearErrors() {
    this.setSubmitError({});
  }

  showSuccessDialog(action) {
    if (_.isEmpty(this.submitError) === false) return;

    this.successDialog.title = this.successMessages[action].title;
    this.successDialog.message = stringFormat(
      this.successMessages[action].message,
      [this.getEmailSentTo.toLowerCase()],
    );
    this.successDialog.show = true;
  }

  closeSuccessDialog() {
    this.successDialog.show = false;
    this.clearFormData();
    this.$router.push("/");
  }

  async onOpenDocument() {
    await this.openW4Pdf({
      id: this.w4PdfId,
      fileTitle: this.w4PdfTitle,
    });

    setTimeout(() => {
      this.closeSuccessDialog();
    }, 1200);
  }

  async mounted() {
    await this.fetchW4Form();
    if (this.isKnight) {
      await this.fetchEmailPreferences();
      await this.fetchPersonalInformation();
    }
  }
}
</script>

<style lang="scss">
div.updateW4 {
  margin-right: 64px !important;
}
.v-card__text {
  font-size: 16px !important;
}
a:hover {
  color: gray;
}
@media only screen and (max-width: 599px) {
  div.updateW4 {
    margin-right: 50px !important;
    .v-toolbar__title {
      overflow: unset;
      white-space: unset;
      font-size: 0.875rem;
    }
    fieldset {
      padding-left: 4px;
      padding-right: 0;
    }
    .v-card__text {
      padding: 4px;
    }
  }
}
</style>
