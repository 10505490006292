var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTable),expression:"showTable"}],staticClass:"pto-entries-table-component"},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-subtitle',{staticClass:"grey lighten-2"},[_vm._v(" Hours Requested ")]),_c('v-form',{ref:"entriesTable"},[_c('v-simple-table',{staticClass:"request-entries-table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"date-column text-left"},[_vm._v("Date")]),_c('th',{staticClass:"category-column text-left"},[_vm._v("Category")]),_c('th',{staticClass:"hour-column text-center"},[_vm._v("Hours")]),_c('th',{staticClass:"action-column text-center"},[_vm._v("Action")])])]),_c('tbody',_vm._l((_vm.dateEntriesArray),function(dateEntry){return _c('tr',{key:((dateEntry.dateStr) + "-" + (dateEntry.entry.code)),class:_vm.hasError(dateEntry)},[_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.dateFormat(dateEntry.dateStr))+" ")]),_c('td',{staticClass:"text-left"},[_c('span',{style:({
                  color: ("var(--pto-cat-bg-color-" + (dateEntry.entry.code) + ")"),
                })},[_vm._v(" "+_vm._s(_vm.categoryFormat(dateEntry.entry.code))+" ")])]),_c('td',{staticClass:"text-center"},[(
                  _vm.employeeSchedulePayType === _vm.config('hourly_pay_type') ||
                  dateEntry.entry.isSplit ||
                  (_vm.isPayroll && _vm.otherEmployeeSelected)
                )?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#.##', '##.##']),expression:"['#.##', '##.##']"}],staticClass:"centered-input",attrs:{"dense":"","value":dateEntry.entry.hours,"rules":[
                  _vm.validateEntriesTable(
                    dateEntry.entry.hours,
                    dateEntry.entry.code,
                    dateEntry.dateStr,
                    dateEntry.entry.isSplit
                  ) ]},on:{"blur":function($event){return _vm.onChangeHours(dateEntry, $event)}}}):_c('span',[_vm._v(" "+_vm._s(dateEntry.entry.hours)+" ")])],1),_c('td',{staticClass:"text-center"},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteDateEntry(dateEntry.dateStr, dateEntry.entry.code)}}},[_vm._v(" mdi-delete ")])],1)])}),0)])],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableErrors),expression:"tableErrors"}],staticClass:"alert-box",attrs:{"type":"error","outlined":""},domProps:{"innerHTML":_vm._s(_vm.tableErrors)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }