<template>
  <v-card class="my-4">
    <v-toolbar class="toolBar" color="primary" dark>
      <v-toolbar-title>
        <i class="mdi mdi-briefcase-arrow-up-down" /> Step 2 - Multiple Jobs or Spouse Works
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="mt-2">
      <v-form ref="multipleJobs">
        <v-row>
          <v-col>
            <p>
              Complete this step if you (1) hold more than one job at a time, or (2) are married filing jointly and your
              spouse also works. The correct amount of withholding depends on income earned from all of these jobs.
            </p>
            <p>Do only one of the following.</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <ol class="alpha-list">
              <li>
                <p>
                  Use the estimator at
                  <a href="https://www.irs.gov/W4App" target="_blank" rel="noopener noreferrer">www.irs.gov/W4App</a>
                  for most accurate withholding for this step (and Steps 3–4).
                  If you or your spouse have self-employment income, use this option;
                </p>
                <p>OR</p>
              </li>
              <li>
                <p>
                  Use the Multiple Jobs Worksheet on
                  <a @keydown.enter.stop="$emit('openPDF', 'page3')" @click.stop="$emit('openPDF', 'page3')">page 3</a>
                  and enter the result in Step 4(c) below;
                </p>
                <p>OR</p>
              </li>
              <li>
                <p>
                  <v-checkbox
                    v-model="multipleJobsFlag"
                    label="If there are only two jobs total, you may check this box."
                  />
                  Do the same on Form W-4 for the other job. This option is generally more accurate than (b) if pay at
                  the lower paying job is more than half of the pay at the higher paying job. Otherwise, (b) is more
                  accurate.
                </p>
              </li>
            </ol>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
/* eslint-disable class-methods-use-this */
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import validators from "@/utils/validators";
import modulePageMixin from "@/utils/mixins";

const store = namespace("payroll/w4");

@Component({
  name: "ComponentName",
  components: {},
})
export default class ComponentName extends Mixins(modulePageMixin) {
  @store.State("formData") formData;

  @store.Mutation("setFormData") setFormData;

  get multipleJobsFlag() {
    return this.formData.multiJobsFlag;
  }

  set multipleJobsFlag(value) {
    this.setFormData({
      multiJobsFlag: value,
      employeeSignature: "",
    });
    this.$emit("validate");
  }

  required(value) {
    return validators.required(value) || "This field is required";
  }
}
</script>

<style scoped>
a {
  text-decoration: underline;
}
.alpha-list {
  list-style-type: lower-alpha;
}
</style>
